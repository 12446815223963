import React, { useState } from 'react';
import { Dialog, Button, FormGroup, InputGroup, Checkbox, HTMLSelect, Spinner } from '@blueprintjs/core';

interface CreateSimulationModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const CreateSimulationModal: React.FC<CreateSimulationModalProps> = ({ isOpen, onClose }) => {
    const [simulationName, setSimulationName] = useState('NewSimulation');
    const [stepType, setStepType] = useState('time');
    const [stepUnit, setStepUnit] = useState('ms');
    const [duration, setDuration] = useState(20000);
    const [stepSize, setStepSize] = useState(1);
    const [modelLanguage, setModelLanguage] = useState('C++');
    const [runnable, setRunnable] = useState(false);
    const [isGenerating, setIsGenerating] = useState(false);

    // const handleSetStepType = (stepType:String) => {
    //     if (stepType === 'steps') {
    //         setStepUnit('ms');
    //     }
    //     setStepType(stepType);
    // }
        
    const handleCreate = async () => {
        setIsGenerating(true);

        // Simulate archive generation with a timeout (replace this with actual generation logic)
        await new Promise((resolve) => setTimeout(resolve, 2000));

        // Trigger download of the generated file
        const blob = new Blob(['Generated archive content'], { type: 'application/zip' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${simulationName}_simulation.zip`;
        link.click();

        // Clean up
        URL.revokeObjectURL(url);
        setIsGenerating(false);
        onClose();
    };

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
            title="Create New Simulation"
            canOutsideClickClose={!isGenerating}
            canEscapeKeyClose={!isGenerating}
        >
            <div className="bp4-dialog-body" style={{padding:'10px'}}>
                <FormGroup label="Simulation Name" labelFor="simulation-name">
                    <InputGroup
                        id="simulation-name"
                        value={simulationName}
                        onChange={(e) => setSimulationName(e.target.value)}
                        placeholder="Enter simulation name"
                    />
                </FormGroup>

                <FormGroup label="Step Unit" labelFor="step-unit">
                    <HTMLSelect
                        id="step-type"
                        value={stepType}
                        onChange={(e) => setStepType(e.currentTarget.value)}
                        options={['time', 'steps']}
                    />
                    <InputGroup
                        id="step-unit"
                        value={stepUnit}
                        onChange={(e) => setStepUnit(e.target.value)}
                        placeholder="ms"
                        disabled={stepType === 'steps'}
                    />
                </FormGroup>

                <FormGroup label="Duration" labelFor="duration">
                    <InputGroup
                        id="duration"
                        type="number"
                        value={duration.toString()}
                        onChange={(e) => setDuration(Number(e.target.value))}
                        placeholder="Enter duration"
                    />
                </FormGroup>

                <FormGroup label="Step Size" labelFor="step-size">
                    <InputGroup
                        id="step-size"
                        type="number"
                        value={stepSize.toString()}
                        onChange={(e) => setStepSize(Number(e.target.value))}
                        placeholder="Enter step size"
                    />
                </FormGroup>

                <FormGroup label="Model Language" labelFor="model-language">
                    <HTMLSelect
                        id="model-language"
                        value={modelLanguage}
                        onChange={(e) => setModelLanguage(e.currentTarget.value)}
                        options={['C', 'C++']}
                    />
                </FormGroup>

                <Checkbox
                    label="Runnable"
                    checked={runnable}
                    onChange={(e) => setRunnable(e.currentTarget.checked)}
                />
            </div>

            <div className="bp4-dialog-footer" style={{padding:'20px'}}>
                <div className="bp4-dialog-footer-actions">
                    <Button onClick={onClose} disabled={isGenerating}>
                        Cancel
                    </Button>
                    <Button intent="primary" onClick={handleCreate} disabled={isGenerating || true}>
                        {isGenerating ? <Spinner size={16} /> : 'Create'}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default CreateSimulationModal;
