import React, { useState } from 'react';
import { Card, Elevation, H5, Button, InputGroup, Collapse, Tooltip, Icon } from '@blueprintjs/core';
import { useLocation } from 'react-router-dom';
import { IconNames } from '@blueprintjs/icons';
import { ParametersCard } from './ParametersCard';

interface Parameter {
    name: string;
    value: string;
}

const SimulationResults: React.FC = () => {
    const location = useLocation();
    const simulationName = location.state?.simulationName;

    const [parameters, setParameters] = useState<Parameter[]>([
        { name: 'Parameter 1', value: '100' },
        { name: 'Parameter 2', value: '30.56' },
    ]);

    const [duration, setDuration] = useState(60);
    const [stepSize, setStepSize] = useState(1);
    const [isTableCollapsed, setIsTableCollapsed] = useState(false);

    const toggleTableCollapse = () => setIsTableCollapsed(!isTableCollapsed);

    const handleParameterChange = (index: number, newValue: string) => {
        const updatedParameters = [...parameters];
        updatedParameters[index].value = newValue;
        setParameters(updatedParameters);
    };

    const handleRunSimulation = () => {
        console.log("Re-executing simulation with parameters:", parameters);
        console.log("Duration:", duration, "Step size:", stepSize);
    };

    const handleSaveParameters = () => {
        console.log("Saving parameters:", parameters);
    }

    return (
        <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            {/* Header with Results Heading and Show/Hide Parameters Button */}
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <H5>Results for {simulationName}</H5>
                <Button
                    icon={isTableCollapsed ? "caret-down" : "caret-up"}
                    minimal
                    onClick={toggleTableCollapse}
                >
                    {isTableCollapsed ? 'Show Parameters' : 'Hide Parameters'}
                </Button>
            </div>

            {/* Flex Container for Graph and Parameters */}
            <div style={{ display: 'flex', flex: 1, gap: '10px', marginTop: '10px', marginBottom: '60px' }}>

                {/* Expandable Simulation Results Card */}
                <Card
                    elevation={Elevation.TWO}
                    style={{
                        flex: isTableCollapsed ? 1 : 2, // Expand graph when parameters are collapsed
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#e0e0e0',
                        borderRadius: '5px'
                    }}
                >
                    {/* Placeholder for the Simulation Graph */}
                    <span>Graph of {simulationName}</span>
                </Card>

                {/* Collapsible Parameters Table */}
                {/* <Card
                    elevation={Elevation.TWO}
                    style={{
                        flex: isTableCollapsed ? 0.01 : 0.5, // Minimize width when collapsed
                        padding: isTableCollapsed ? '10px 5px' : '15px',
                        overflow: 'hidden',
                        transition: 'flex 0.3s ease, padding 0.3s ease' // Smooth transition for flex and padding
                    }}
                >
                    <Collapse isOpen={!isTableCollapsed}>
                        <table className="bp4-html-table bp4-html-table-striped bp4-html-table-bordered" style={{ width: '100%', marginTop: '10px' }}>
                            <thead>
                                <tr>
                                    <th>Parameter</th>
                                    <th>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {parameters.map((parameter, index) => (
                                    <tr key={index}>
                                        <td>{parameter.name}</td>
                                        <td>
                                            <InputGroup
                                                value={parameter.value}
                                                onChange={(e) => handleParameterChange(index, e.target.value)}
                                                placeholder="Enter value"
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Collapse>
                </Card> */}
                <ParametersCard isTableCollapsed={isTableCollapsed} toggleTableCollapse={toggleTableCollapse} />
            </div>

            {/* Sticky Footer with Simulation Controls */}
            <div style={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                backgroundColor: '#f8f8f8',
                padding: '10px 20px',
                borderTop: '1px solid #ddd',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
            }}>
                <span>Execution Time: 12:30 PM</span>
                <Tooltip content='Verification result'>
                    <Icon icon={IconNames.TickCircle} />
                </Tooltip>
                <div>Simulation Duration
                    <InputGroup
                        placeholder="Duration"
                        // type="number"
                        value={duration.toString()}
                        onChange={(e) => setDuration(Number(e.target.value))}
                        style={{ width: '80px' }}
                    />
                </div>
                <span>Execution Step Size
                    <InputGroup
                        placeholder="Step Size"
                        // type="number"
                        value={stepSize.toString()}
                        onChange={(e) => setStepSize(Number(e.target.value))}
                        style={{ width: '80px' }}
                    />
                </span>
                <Tooltip content="Save the parameters for next execution">
                    <Button intent="primary" onClick={handleSaveParameters} icon={IconNames.TableSync}>
                        Save Parameters
                    </Button>
                </Tooltip>
                <Tooltip content="You need to use RunnableSimulation to enable running from workspace">
                    <Button intent="primary" onClick={handleRunSimulation} icon={IconNames.Play} disabled={true}>
                        Run
                    </Button>
                </Tooltip>
            </div>
        </div>
    );
};

export default SimulationResults;
