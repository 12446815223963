import { User } from "oidc-client-ts"

function getUser() {
    const oidcStorage = localStorage.getItem(`oidc.user:<your authority>:<your client id>`)
    if (!oidcStorage) {
        return null;
    }

    return User.fromStorageString(oidcStorage);
}

export const getAllUserSimulations = async () => {
    const user = getUser();
    const token = user?.access_token;
    console.log(`Getting simulations for ${user?.profile.email}`);
    
    return {}
}

export const getAllTeamSimulations = async () => {
    const user = getUser();
    const token = user?.access_token;
    const groups = user?.profile.groups as string[];
    console.log(`Getting simulations for ${groups}`);
    
    return {}
}

// export const getPosts = createAsyncThunk(
//     "store/getPosts",
//     async () => {
//         const user = getUser();
//         const token = user?.access_token;
//         return fetch("https://api.example.com/posts", {
//             headers: {
//                 Authorization: `Bearer ${token}`,
//             },
//         });
//     },
//     // ...
// )