import React, { useState } from "react";
import {
  Alignment,
  AnchorButton,
  Classes,
  Navbar,
  NavbarGroup,
  NavbarHeading,
  NavbarDivider,
  Button,
  Tooltip
} from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Link, useLocation } from "react-router-dom";
import CreateSimulationModal from "./CreateSimulationModal";
import { useAuth } from "react-oidc-context";

export interface MainNavigationProps { }

export const MainNavigation: React.FC<MainNavigationProps> = () => {
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const auth = useAuth();
  const email = auth.user?.profile.email;
  const firstName = auth.user?.profile.given_name;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const openUserProfile = () => {
    if (auth.user && auth.user.profile) {
      const keycloakUrl = auth.user.profile.iss; // issuer URL from user profile
      const profileUrl = `${keycloakUrl}/account`; // Keycloak profile URL
      window.open(profileUrl, '_blank');
    }
  };
  // Determine the active route for highlighting the Results button
  const isResultsPage = location.pathname.startsWith('/results');
  const isAllSimulationsPage = location.pathname === '/';

  return (
    <>
      <Navbar className={Classes.DARK}>
        <NavbarGroup align={Alignment.LEFT}>
          <NavbarHeading>CppModel Workspace</NavbarHeading>
          <NavbarDivider />
          <Link to="/">
            <Button
              text="All Simulations"
              active={isAllSimulationsPage}
              minimal
              rightIcon={IconNames.Home}
            />
          </Link>
          <Button
            text="Results"
            disabled={!isResultsPage}
            active={isResultsPage}
            minimal
            rightIcon={IconNames.TimelineLineChart}
          />
          <Button
            text="New Simulation"
            onClick={openModal}
            minimal
            rightIcon={IconNames.SeriesAdd}
          />
          <NavbarDivider />
          <Tooltip content="Open documentation in new window">
            <AnchorButton
              text="Documentation"
              href="https://www.cppmodel.com/docs/Overview"
              target="_blank"
              minimal
              rightIcon={IconNames.Help}
            />
          </Tooltip>
          <NavbarDivider />
          <Tooltip content="Open user settings in new window">
            <AnchorButton
              href="#"
              onClick={() => {openUserProfile()}}
              text={`Settings (${(firstName) ? firstName : email})`}
              target="_blank"
              minimal
              rightIcon={IconNames.User}
            />
          </Tooltip>
        </NavbarGroup>
        <NavbarGroup align={Alignment.RIGHT}>
          <AnchorButton
            text="Logout"
            onClick={() => auth.signoutRedirect()}
            minimal
            rightIcon={IconNames.LogOut}
          />
        </NavbarGroup>
      </Navbar>
      <CreateSimulationModal isOpen={isModalOpen} onClose={closeModal} />
    </>
  );
};
