import React, { useState } from 'react';
import { Card, Elevation, H5, Button, InputGroup, Collapse } from '@blueprintjs/core';

export interface Parameter {
  name: string;
  value: string;
}

export const ParametersCard: React.FC<{ isTableCollapsed: boolean; toggleTableCollapse: () => void }> = ({
  isTableCollapsed,
  toggleTableCollapse,
}) => {
  const [simulationParameters, setSimulationParameters] = useState<Parameter[]>([
    { name: 'Simulation Param 1', value: 'Value 1' },
    { name: 'Simulation Param 2', value: 'Value 2' },
  ]);

  const [cppModelParameters, setCppModelParameters] = useState<Parameter[]>([
    { name: 'CppModel Param 1', value: 'Value A' },
    { name: 'CppModel Param 2', value: 'Value B' },
  ]);

  const handleParameterChange = (setParams: React.Dispatch<React.SetStateAction<Parameter[]>>, index: number, newValue: string) => {
    setParams((prevParams) => {
      const updatedParams = [...prevParams];
      updatedParams[index].value = newValue;
      return updatedParams;
    });
  };

  return (
    <Card
      elevation={Elevation.TWO}
      style={{
        flex: isTableCollapsed ? 0.01 : 0.5, // Reduce width to half when collapsed
        padding: isTableCollapsed ? '10px 5px' : '15px',
        overflow: 'hidden',
        transition: 'flex 0.3s ease, padding 0.3s ease' // Smooth transition for flex and padding
      }}
    >
      <Collapse isOpen={!isTableCollapsed}>
        {/* Simulation Parameters Section */}
        <div>
          <H5>Simulation Parameters</H5>
          <table className="bp4-html-table bp4-html-table-striped bp4-html-table-bordered" style={{ width: '100%', marginTop: '10px' }}>
            <thead>
              <tr>
                <th>Parameter</th>
                <th align='right'>Value</th>
              </tr>
            </thead>
            <tbody>
              {simulationParameters.map((parameter, index) => (
                <tr key={index}>
                  <td>{parameter.name}</td>
                  <td align='right'>
                    <InputGroup
                      value={parameter.value}
                      onChange={(e) => handleParameterChange(setSimulationParameters, index, e.target.value)}
                      placeholder="Enter value"
                      style={{ width: '80px' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* CppModel Parameters Section */}
        <div style={{ marginTop: '20px' }}>
          <H5>Workspace Parameters</H5>
          <table className="bp4-html-table bp4-html-table-striped bp4-html-table-bordered" style={{ width: '100%', marginTop: '10px' }}>
            <thead>
              <tr>
                <th>Parameter</th>
                <th align='right'>Value</th>
              </tr>
            </thead>
            <tbody>
              {cppModelParameters.map((parameter, index) => (
                <tr key={index}>
                  <td>{parameter.name}</td>
                  <td align='right'>
                    <InputGroup
                      value={parameter.value}
                      onChange={(e) => handleParameterChange(setCppModelParameters, index, e.target.value)}
                      placeholder="Enter value"
                      style={{ width: '80px' }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Collapse>
    </Card>
  );
};
